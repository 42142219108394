import moment from "moment";
import { addDateFormatting, calculateExpDate } from "@cogitate/ui-utils-core-test";
import { dateKeys } from "ui/data/constants/keys";
let d = moment();
let dformat = d.format(dateKeys.DateFormatyyymmdd);
let defaultExpiryDate = calculateExpDate(dformat, "12");
let DefQuoteExpDate = moment().toDate();
let defaultQuoteExpDate = addDateFormatting(dateKeys.Days, DefQuoteExpDate, 30);
import { getStoreState } from "@cogitate/ui-utils-core-test";
import { setUserRole } from "ui/utilities/helper";
import { storeKeys } from "ui/data/constants/keys";

function getInitalState() {
  let initialData = getStoreState(storeKeys.DataReducer).initialData;
  initialData = {
    ...initialData,
    EffectiveDate: dformat,
    ExpirationDate: defaultExpiryDate,
    QuoteDate: dformat,
    QuoteExpDate: defaultQuoteExpDate,
    Attributes: {
      ...initialData?.Attributes,
      UISchemaVersion: process.env.NEXT_PUBLIC_DEFAULT_VERSION,
    },
  };
  //initialData.Risks.Properties[0].Address.IsManual="false";
  return setUserRole(initialData)
}
export { getInitalState };
