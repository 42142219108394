const alertMsgs = {
  PaymentMsg: {
    InvalidEmail: "Please enter valid Email Address",
    AmountInvalidPlan: 'Amount should be equal or more than Selected Payment Plan',
    TransactionTypeInvalid: 'Please select Transaction Type',
    FailedPayment: 'Payment Failed, Please try again',
    PaymentOption: "Please select Payment Option.",
    MortgageeBillRequirement: "Please add at least one Mortgagee on the policy to opt for payment method as Mortgagee Bill."
  },
  PaymentStatus: {
    Success: "Payment details has been updated successfully.",
    PaymentSuccess: "Payment done successfully.",
    FailedPayment: "Payment failed !"
  },
  Login: {
    LoginFailed: "Wrong user id or password. Try again."
  },
  RiskQualification: {
    NotQualifiedRisks: "Some of the Risks are not qualified."
  },
  BusinessInfo: {
    DOTNotFound: "No matching record found.",
    DOTNotEntered: "Please enter a valid DOT Number.",
    PolicyExpirationDate:
      "Policy Expiration Date cannot be before Effective Date."
  },
  Locations: {
    AtLeastOneLocation: "Please add at least one location to proceed !!!",
    AtMostFiveLocation: "At most 5 addresses are allowed !!!",
    AtLeastOneMailingAddress: "At Least 1 Mailing Address is must !!!",
    OnlyOneMailingAddress: "Only one mailing is allowed !!!",
    MailingAddressShouldBeInGA: "Mailing address should be in GA state",
    DuplicateAddress: "Duplicate Address found !!!",
    LocationSaved: "Location Saved Successfully."
  },
  Shared: {
    TimeOut: "Session Timed Out !!",
    PolicyBound: "Policy bound successfully! Documents have been sent to the agent & Insured for signatures",
    LogOut: "You have successfully logged out from the system.",
    SomethingWentWrong: "Ooops something went wrong !!",
    RiskIsMust: "Atleast one vehicle is must.",
    CannotHaveMoreThan100: "Cannot have more than 100%.",
    PercentageOwned: "Percentage owned should be between 0% and 100%.",
    OneOfTheRaterFailed: "One of the rater failed.",
    UWReferral: "Your policy is submitted for underwriter referral.",
    AdditionalInsuredCoverages: "Please add Additional Insured coverages.",
    ToDate: "Please select to date as well.",
    SuccessRequestCoverageBound: "Sent for E-Signature.",
    FailedRequestCoverageBound: "Ooops something went wrong while sending for Signature!!",
    PastBiteHistory: "Dog liability coverage not available.",
    LessDogDetails: "Require entry for each number of dogs selected.",
    MoreDogDetails: "Dog Liability Details cannot be more than number of dogs selected.",
    NoChange:"No Change History",
    RaterTechnicalError: "We were unable to accurately calculate the premium due to missing property data, please contact underwriting."
  },
  ActionButtons: {
    SaveFailed: "Failed policy update.",
    EmailSendSuccess: "Email sent successfully.",
    EmailSendFailed: "Email sending failed."
  },
  ToasterMsg: {
    ReferToUW:
      "Risk has been submitted to Underwriter for some further review. Your Underwriter will contact you shortly.",
    SubmissionDeclined:
      "Submission has been declined as the property has an ineligible risk."
  },
  Endorsement: {
    RejectEndorsement: "Endorsement Rejected successfully.",
    OngoingEndorsement:
      "There is already an ongoing Transaction. Kindly continue with it or revert it.",
    EndorsementEffectiveGreater:
      "Endorsement effective date should not be greater than policy expiration",
    PriorBindDate:
      "Endorsement effective date cannot be prior to last bind transaction date.",
    EndorsementStarted: "Endorsement Started",
    EndorsementReverted: "Endorsement reverted successfully",
    EndorsementSubmitted: "Endorsment is submitted for UW Approval",
    DownpaymentAdjusted: "Down payment adjusted successfully.",
    EndorsementBindSuccess: "Policy is Endorsed successfully.",
    EndorsementBindFailure: "Failed to Endorsed policy.",
    QuoteOfferedSuccess: "Email for Endorsed Quote sent successfully.",
    QuoteOfferedFailed: "Failed to send Endorsed Quote Email."
  },
  Cancellation: {
    PolicyCancelled: "Policy Cancelled Successfully",
    PolicyCancelledFailure: "Policy Cancelled Failed",
    PolicyCancellationReverted: "Policy Cancellation Reverted Successfully",
    PolicyCancellationRevertedFailed: "Policy Cancellation Revert Failed",
    PolicyCancellationRejected: "Policy Cancellation Rejected Successfully",
    PolicyCancellationRejectedFailed: "Policy Cancellation Reject Failed",
    PolicyCancellationOnHold: "Policy Cancellation is on hold",
    PolicyCancellationOnHoldFailed: "Policy Cancellation on hold Failed",
    SendForInsuredSign: "Policy sent for Insured Signature",
    SendForInsuredSignFailed: "Policy sent for Insured Signature Failed",
    ResendForInsuredSign: "Policy Re-sent for Insured Signature",
    ResendForInsuredSignFailed: "Policy Re-sent for Insured Signature Failed",
    PolicyCancellationApprovalPending:
      "Policy Cancellation request sent for Underwriter Approval.",
    AdjustPremiumFailed: "Adjust Premium Failed.",
    IncorrectFlatCancellation:
      "Flat Cancellation is not possible for a date other than Effective Date of Coverage."
  },
  Reinstatement: {
    PolicyReinstated:
      "Policy Reinstated Successfully & Agent will be notified Shortly!",
    PolicyReinstateFailure: "Policy Reinstated Failed.",
    CalculatePremiumFailure: "Premium Calculation Failed.",
    ReinstateRejected: "Reinstate request has been Rejected.",
    PolicyReinstatedReverted: "Policy Reinstated Reverted Successfully.",
    PolicyReinstatedRevertedFailed: "Policy Cancellation Revert Failed.",
    PolicyReinstateApprovalPending:
      "Policy Reinstate request sent for Underwriter Approval."
  }
}
const validationMsgs = {
  Year: {
    WeeksPerYear: "Weeks entered should be between 1 and 52.",
    ValidYear: "Year built is not valid.",
    CurrentYear: "Year built cannot be greater than current year.",
    RoofUpdateYear:
      "Roof Update Year cannot be less than year built or greater than current year.", // to be discuss
    HeatingUpdateYear:
      "Heating Update Year cannot be less than year built or greater than current year.", // to be discuss
    ElectricalUpdateYear:
      "Electrical Update Year cannot be less than year built or greater than current year.", // to be discuss
    PlumbingUpdateYear:
      "Plumbing Update Year cannot be less than year built or greater than current year." // to be discuss
  },
  Locations: {
    SelectedState: "Risk address located in state that is not yet available."
  },
  LOB: {
    NonSelected: "Please select a LOB to continue.",
    NonImplemented: "This LOB has not been implemented yet."
  },
  PolicyHolder: {
    PhoneNumber: "Phone Number is not valid.",
    Email: "Email is not valid.",
    AreaSQFT: "Area should be greater than 0.",
    validCoverageA: "Coverage A cannot be smaller than 150k.",
    validCoverageB: "Coverage B cannot be greater than Coverage A.",
    validCoverageC: "Coverage C cannot be greater than Coverage A.",
    validCoverageD: "Coverage D cannot be greater than Coverage A.",
    validLossAssessment: "Loss Assessment should be between $1000 to $100,000.",
    IsPropertyLLCTrust:
      "A trust cannot be the primary policyholder in this program. If applicable, you may add a trust as an Additional Insured."
  },
  RiskQualifiers: {
    IsApplicantNotMisRepresentedAnyFacts:
      "Cannot proceed without confirming the stated statements."
  }
}

export { alertMsgs, validationMsgs }
